import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const getBackground = graphql`
  query {
    default1: file(relativePath: { eq: "jun-header.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const StyledHero = ({ img, children, className, home }) => {
  const data = useStaticQuery(getBackground)
  return (
    <BackgroundImage
      fluid={img || data.default1.childImageSharp.fluid}
      className={className}
      home={home}
    >
      {children}
    </BackgroundImage>
  )
}

export default styled(StyledHero)`
  height: ${props => (props.home ? "calc(100vh - 60px);" : "25vh")};
  background: linear-gradient(rgba(255, 117, 0, 0.2), rgba(0, 0, 0, 0.7));
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
  display: flex;
`
